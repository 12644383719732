import React from 'react';

import Apps from '../data/apps.json'
import strings from '../styles/strings/strings';
import dimes from '../styles/dimes';

import Card from '../component/card';
import {Container} from "reactstrap";

const Dashboard = () => {
    const educationFree = Apps.educationFree;
    const educationPro = Apps.educationPro;
    const toolsFree = Apps.toolsFree;
    const toolsPro = Apps.toolsPro;
    const entreteniment = Apps.entreteniment;

    return (
        <div className="section section-basic" id="basic-elements">
            <Container style={{maxWidth: "100%"}}>
                <h1 className="title">{strings.SCHOOLAPPS}</h1>
                <h4 className="body">{strings.SCHOOLAPPSMSN}</h4>
                <h2 className="subtitle" style={{marginLeft: dimes.SHORTMARGIN}}>{strings.FREE}</h2>
            </Container>
            {
                educationFree.map((app) => <Card key = {app.key} app = {app}/>)
            }
            <Container style={{maxWidth: "100%"}}>
                <h2 className="subtitle">{strings.PRO}</h2>
            </Container>
            {
                educationPro.map((app) => <Card key = {app.key} app = {app}/>)
            }
            <Container style={{maxWidth: "100%"}}>
                <h1 className="title">{strings.PRODUCTIVEAPPS}</h1>
                <h4 className="body">{strings.PRODUCTIVEAPPSMSN}</h4>
                <h2 className="subtitle" style={{marginLeft: dimes.SHORTMARGIN}}>{strings.FREE}</h2>
            </Container>
            {
                toolsFree.map((app) => <Card key = {app.key} app = {app}/>)
            }
            <Container style={{maxWidth: "100%"}}>
                <h2 className="subtitle">{strings.PRO}</h2>
            </Container>
            {
                toolsPro.map((app) => <Card key = {app.key} app = {app}/>)
            }
            <Container style={{maxWidth: "100%"}}>
                <h1 className="title">{strings.ENTERTAINMENTAPPS}</h1>
                <h4 className="body">{strings.ENTERTAINMENTAPPSMSN}</h4>
            </Container>
            {
                entreteniment.map((app) => <Card key = {app.key} app = {app}/>)
            }
        </div>
    );
}

export default Dashboard;