import React, {useState} from "react";

import strings from "../styles/strings/strings";

import PropTypes from 'prop-types';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import Typography from '@material-ui/core/Typography';
import {withStyles} from '@material-ui/core/styles';
import {useHistory} from "react-router-dom";

import {AiFillInfoCircle} from "react-icons/ai";
import {SiGoogleplay, SiAppstore, SiHuawei, SiYoutube} from "react-icons/si";

const CardApp = (props) =>{
    const {app, classes} = props

    const history = useHistory();

    const [value] = useState(0);

    const onPressCard = () => {
        if(navigator.userAgent.match(/Android/i)){
            if(app.play_store === ""){
                history.push('/'+app.name.toLowerCase().replace(/ /g, ''), {app})
            }else{
                window.open(app.play_store, '_blank', 'noopener,noreferrer');
            }
        }else if(navigator.userAgent.match(/webOS/i) || navigator.userAgent.match(/iPhone/i) || navigator.userAgent.match(/iPad/i) || navigator.userAgent.match(/iPod/i)){
            if(app.app_store === ""){
                history.push('/'+app.name.toLowerCase().replace(/ /g, ''), {app})
            }else{
                window.open(app.app_store, '_blank', 'noopener,noreferrer');
            }
        }else{
            history.push('/'+app.name.toLowerCase().replace(/ /g, ''), {app})
        }
    }
    
    const goURL = (key) => {
        switch (key) {
            case "play_store":
                window.open(app.play_store, '_blank', 'noopener,noreferrer');
                break;
            case "app_gallery":
                window.open(app.app_gallery, '_blank', 'noopener,noreferrer');
                break;
            case "app_store":
                window.open(app.app_store, '_blank', 'noopener,noreferrer');
                break;
            case "youtube":
                window.open(app.youtube, '_blank', 'noopener,noreferrer');
                break;
            case "info":
                history.push('/'+app.name.toLowerCase().replace(/ /g, ''), {app})
                break;
            default:
                break;
        }
    }

    return (
        <Card className={classes.root}>
            <CardActionArea onClick={() => onPressCard()}>
                <CardMedia
                    className={classes.media}
                    image={app.banner}
                    title={app.name}
                />
                <CardContent className={classes.content}>
                    <Typography className={classes.letter} gutterBottom variant="h5" component="h2">{""}</Typography>
                    <Typography className={classes.letter} variant="body2" color="textSecondary" component="p">{app.descrip}</Typography>
                </CardContent>
            </CardActionArea>
            <CardActions className={classes.actions} style={{whiteSpace: 'pre-line'}}>
                <BottomNavigation value={value}
                    onChange={(event, newValue) => {
                        goURL(newValue);
                    }}
                    showLabels className={classes.groupButton}>
                    <BottomNavigationAction className={classes.button} label={strings.GPLAYSTORE} value="play_store" icon={<SiGoogleplay color={classes.button.color} fontSize={"20px"} />} />
                    {app.app_store !== "" && <BottomNavigationAction className={classes.button} label={strings.AAPPSTORE} value="app_store" icon={<SiAppstore color={classes.button.color} fontSize={"23px"} />} />}
                    {app.app_gallery !== "" && <BottomNavigationAction className={classes.button} label={strings.HAPPGALLERY} value="app_gallery" icon={<SiHuawei color={classes.button.color} fontSize={"25px"} />} />}
                    {app.youtube !== "" && <BottomNavigationAction className={classes.button} label={strings.TUTORIAL} value="youtube" icon={<SiYoutube color={classes.button.color} fontSize={"23px"} />} />}
                    {<BottomNavigationAction className={classes.button} label={strings.MOREINFO} value="info" icon={<AiFillInfoCircle color={classes.button.color} fontSize={"23px"} />} />}
                </BottomNavigation>
            </CardActions>
        </Card>
    );
}

const useStyles = {
    root: {
      maxWidth: 345,
      margin: 25,
      backgroundColor: props => props.app.color,
    },

    media: {
      height: 160,
    },

    content: {
        paddingTop: 0,
    },

    actions: {
        backgroundColor: props => props.app.background,
        borderColor:  props => props.app.background,
    },

    groupButton: {
        width: 500,
        backgroundColor: props => props.app.background,
    },

    button: {
        color: props => props.app.color,
    },

    letter: {
        color: props => props.app.background
    }
};


CardApp.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(useStyles)(CardApp);