import React, {useState, useEffect} from 'react';

import Apps from '../data/apps.json';
import strings from '../styles/strings/strings';
import dimes from '../styles/dimes';

import {useLocation, useHistory} from 'react-router-dom';
import {Button, UncontrolledTooltip} from "reactstrap";
import {IoHomeSharp, IoArrowBackSharp} from "react-icons/io5";

const Privacy = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [isDirect, setIsDirect] = useState(true);
    const [dataApp, setDataApp] = useState({});

    const {pathname, state={app: {}}} = useLocation();
    const history = useHistory();

    const educationFree = Apps.educationFree;
    const educationPro = Apps.educationPro;
    const toolsFree = Apps.toolsFree;
    const toolsPro = Apps.toolsPro;
    const entreteniment = Apps.entreteniment;

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    useEffect(() => {
        init();
    }, []);

    const init = () => {
        const aPathname = pathname.split('/');
        
        switch ('/'+aPathname[1]) {
            case '/appa':
                if(Object.keys(state.app).length === 0){
                    setDataApp(educationFree[0]);
                    setIsDirect(true);
                    setIsLoading(false);
                }else{
                    setDataApp(state.app);
                    setIsDirect(false);
                    setIsLoading(false);
                }
                break;
            case '/cpeum':
                if(Object.keys(state.app).length === 0){
                    setDataApp(educationFree[1]);
                    setIsDirect(true);
                    setIsLoading(false);
                }else{
                    setDataApp(state.app);
                    setIsDirect(false);
                    setIsLoading(false);
                }
                break;
            case '/izzystatistics':
                if(Object.keys(state.app).length === 0){
                    setDataApp(educationFree[2]);
                    setIsDirect(true);
                    setIsLoading(false);
                }else{
                    setDataApp(state.app);
                    setIsDirect(false);
                    setIsLoading(false);
                }
                break;
            case '/zoodex':
                if(Object.keys(state.app).length === 0){
                    setDataApp(educationFree[3]);
                    setIsDirect(true);
                    setIsLoading(false);
                }else{
                    setDataApp(state.app);
                    setIsDirect(false);
                    setIsLoading(false);
                }
                break;
            case '/izzystudy':
                if(Object.keys(state.app).length === 0){
                    setDataApp(educationFree[4]);
                    setIsDirect(true);
                    setIsLoading(false);
                }else{
                    setDataApp(state.app);
                    setIsDirect(false);
                    setIsLoading(false);
                }
                break;
            case '/elementable':
                if(Object.keys(state.app).length === 0){
                    setDataApp(educationFree[5]);
                    setIsDirect(true);
                    setIsLoading(false);
                }else{
                    setDataApp(state.app);
                    setIsDirect(false);
                    setIsLoading(false);
                }
                break;

            case '/appapro':
                if(Object.keys(state.app).length === 0){
                    setDataApp(educationPro[0]);
                    setIsDirect(true);
                    setIsLoading(false);
                }else{
                    setDataApp(state.app);
                    setIsDirect(false);
                    setIsLoading(false);
                }
                break;
            case '/cpeumpro':
                if(Object.keys(state.app).length === 0){
                    setDataApp(educationPro[1]);
                    setIsDirect(true);
                    setIsLoading(false);
                }else{
                    setDataApp(state.app);
                    setIsDirect(false);
                    setIsLoading(false);
                }
                break;
            case '/izzystatisticspro':
                if(Object.keys(state.app).length === 0){
                    setDataApp(educationPro[2]);
                    setIsDirect(true);
                    setIsLoading(false);
                }else{
                    setDataApp(state.app);
                    setIsDirect(false);
                    setIsLoading(false);
                }
                break;
            case '/izzystudypro':
                if(Object.keys(state.app).length === 0){
                    setDataApp(educationPro[3]);
                    setIsDirect(true);
                    setIsLoading(false);
                }else{
                    setDataApp(state.app);
                    setIsDirect(false);
                    setIsLoading(false);
                }
                break;
            case '/elementablepro':
                if(Object.keys(state.app).length === 0){
                    setDataApp(educationPro[4]);
                    setIsDirect(true);
                    setIsLoading(false);
                }else{
                    setDataApp(state.app);
                    setIsDirect(false);
                    setIsLoading(false);
                }
                break;

            case '/izzymarket':
                if(Object.keys(state.app).length === 0){
                    setDataApp(toolsFree[0]);
                    setIsDirect(true);
                    setIsLoading(false);
                }else{
                    setDataApp(state.app);
                    setIsDirect(false);
                    setIsLoading(false);
                }
                break;
            case '/enigma':
                if(Object.keys(state.app).length === 0){
                    setDataApp(toolsFree[1]);
                    setIsDirect(true);
                    setIsLoading(false);
                }else{
                    setDataApp(state.app);
                    setIsDirect(false);
                    setIsLoading(false);
                }
                break;
            case '/carlost':
                if(Object.keys(state.app).length === 0){
                    setDataApp(toolsFree[2]);
                    setIsDirect(true);
                    setIsLoading(false);
                }else{
                    setDataApp(state.app);
                    setIsDirect(false);
                    setIsLoading(false);
                }
                break;
            case '/izzyscanner':
                if(Object.keys(state.app).length === 0){
                    setDataApp(toolsFree[3]);
                    setIsDirect(true);
                    setIsLoading(false);
                }else{
                    setDataApp(state.app);
                    setIsDirect(false);
                    setIsLoading(false);
                }
                break;

            case '/izzymarketpro':
                if(Object.keys(state.app).length === 0){
                    setDataApp(toolsPro[0]);
                    setIsDirect(true);
                    setIsLoading(false);
                }else{
                    setDataApp(state.app);
                    setIsDirect(false);
                    setIsLoading(false);
                }
                break;
            case '/enigmapro':
                if(Object.keys(state.app).length === 0){
                    setDataApp(toolsPro[1]);
                    setIsDirect(true);
                    setIsLoading(false);
                }else{
                    setDataApp(state.app);
                    setIsDirect(false);
                    setIsLoading(false);
                }
                break;
            case '/carlostpro':
                if(Object.keys(state.app).length === 0){
                    setDataApp(toolsPro[2]);
                    setIsDirect(true);
                    setIsLoading(false);
                }else{
                    setDataApp(state.app);
                    setIsDirect(false);
                    setIsLoading(false);
                }
                break;
            case '/izzyscannerpro':
                if(Object.keys(state.app).length === 0){
                    setDataApp(toolsPro[3]);
                    setIsDirect(true);
                    setIsLoading(false);
                }else{
                    setDataApp(state.app);
                    setIsDirect(false);
                    setIsLoading(false);
                }
                break;

            case '/memorizate':
                if(Object.keys(state.app).length === 0){
                    setDataApp(entreteniment[0]);
                    setIsDirect(true);
                    setIsLoading(false);
                }else{
                    setDataApp(state.app);
                    setIsDirect(false);
                    setIsLoading(false);
                }
                break;
            case '/emojitxt':
                if(Object.keys(state.app).length === 0){
                    setDataApp(entreteniment[1]);
                    setIsDirect(true);
                    setIsLoading(false);
                }else{
                    setDataApp(state.app);
                    setIsDirect(false);
                    setIsLoading(false);
                }
                break;

            default:
                if(Object.keys(state.app).length === 0){
                    history.push('/')
                }else{
                    setDataApp(state.app);
                    setIsDirect(false);
                    setIsLoading(false);
                }
                break;
        }
    }

    return (
        isLoading ? 
        <></>
        :
        <div className="sectionG section-basic" style={{paddingTop: 0, paddingBottom: 0}}>
            <Button style={{position: "absolute", top: 90, left: dimes.MARGIN, background: dataApp.primary_color, zIndex: 99}}
                className="btn-icon btn-neutral btn-simple"
                color="default"
                id="tooltip230450899"
                onClick={() => isDirect ? history.push('/'):history.goBack()}>
                {isDirect ? <IoHomeSharp color="white" fontSize={"20px"} />:<IoArrowBackSharp color="white" fontSize={"20px"}/>}
            </Button>
            <UncontrolledTooltip delay={0} target="tooltip230450899">
                {"Regresar"}
            </UncontrolledTooltip>
            <div className="section section-basic" style={{paddingBottom: 0,}}>
                <div className="sectionV section-basic" id="basic-elements" style={{paddingTop:0, paddingBottom: 0}}>
                    <h1 className="title" style={{color: dataApp.primary_color, marginLeft: dimes.LARGEMARGIN, marginRight: dimes.LARGEMARGIN, textAlign: 'center'}}>
                        {dataApp.name}
                    </h1>
                    <h3 className="subtitle" style={{color: dataApp.primary_color, marginLeft: dimes.LARGEMARGIN, marginRight: dimes.LARGEMARGIN, textAlign: 'center'}}>
                        {dataApp.subtitle}
                    </h3>
                </div>
                <div className="sectionV section-basic" id="images">
                    <img
                        alt="..."
                        className="img-fluid rounded shadow-lg"
                        src={dataApp.icon}
                        style={{width: "195px", height: "195px", marginTop: 0, marginBottom: 0}}/>
                </div>
            </div>
            <h2 className="title" style={{marginTop: 0, marginBottom: dimes.SHORTMARGIN/2, color: dataApp.primary_color}}>
                {strings.PRIVACYPOLICY}
            </h2>
            <h3 className="title" style={{marginBottom: dimes.SHORTMARGIN/2, color: dataApp.primary_color}}>
                {strings.INTRODUCTION}
            </h3>
            <p className="body" style={{marginLeft: dimes.LARGEMARGIN, marginRight: dimes.LARGEMARGIN, textAlign: 'justify', color: dataApp.primary_color, fontSize: 18}}>
                La presente Política de Privacidad establece los términos en que <b>{strings.DASHBOARD}</b> usa y protege la información que es proporcionada por sus usuarios al momento de utilizar la aplicación <b>{dataApp.name}</b>. Estamos comprometidos con la seguridad de los datos de nuestros usuarios o de sus dispositivos.
            </p>
            <h3 className="title" style={{marginBottom: dimes.SHORTMARGIN/2, color: dataApp.primary_color}}>
                {strings.COLLECTINFO}
            </h3>
            <p className="body" style={{marginLeft: dimes.LARGEMARGIN, marginRight: dimes.LARGEMARGIN, textAlign: 'justify', color: dataApp.primary_color, fontSize: 18}}>
                {dataApp.privacy.collect_info.description}
            </p>
            <h3 className="subtitle" style={{marginLeft: dimes.LARGEMARGIN, marginBottom: dimes.SHORTMARGIN/2, color: dataApp.primary_color}}>
                {strings.COLLECTINFO1}
            </h3>
            <p className="body" style={{marginLeft: dimes.LARGEMARGIN, marginRight: dimes.LARGEMARGIN, textAlign: 'justify', color: dataApp.primary_color, fontSize: 18}}>
                <ul style={{marginBottom: 0}}>
                    {
                        dataApp.privacy.collect_info.permissions.map((permission) => (
                            <li style={{color: dataApp.primary_color}}>{permission}</li>
                        ))
                    }
                </ul>
            </p>
            <h3 className="subtitle" style={{marginLeft: dimes.LARGEMARGIN, marginBottom: dimes.SHORTMARGIN/2, color: dataApp.primary_color}}>
                {strings.COLLECTINFO2}
            </h3>
            <p className="body" style={{marginLeft: dimes.LARGEMARGIN, marginRight: dimes.LARGEMARGIN, textAlign: 'justify', color: dataApp.primary_color, fontSize: 18}}>
                {dataApp.privacy.collect_info.permissions_description}
            </p>
            <h3 className="title" style={{marginBottom: dimes.SHORTMARGIN/2, color: dataApp.primary_color}}>
                {strings.SHAREINFO}
            </h3>
            <p className="body" style={{marginLeft: dimes.LARGEMARGIN, marginRight: dimes.LARGEMARGIN, textAlign: 'justify', color: dataApp.primary_color, fontSize: 18}}>
                {dataApp.privacy.share_info.description}
            </p>
            {
                dataApp.privacy.share_info.permissions.length > 0 &&
                <>
                <h3 className="subtitle" style={{marginLeft: dimes.LARGEMARGIN, marginBottom: dimes.SHORTMARGIN/2, color: dataApp.primary_color}}>
                    {strings.SHAREINFO1}
                </h3>
                <p className="body" style={{marginLeft: dimes.LARGEMARGIN, marginRight: dimes.LARGEMARGIN, textAlign: 'justify', color: dataApp.primary_color, fontSize: 18}}>
                    <ul style={{marginBottom: 0}}>
                        {
                            dataApp.privacy.share_info.permissions.map((permission) => (
                                <li style={{color: dataApp.primary_color}}>{permission}</li>
                            ))
                        }
                    </ul>
                </p>
                <h3 className="subtitle" style={{marginLeft: dimes.LARGEMARGIN, marginBottom: dimes.SHORTMARGIN/2, color: dataApp.primary_color}}>
                    {strings.SHAREINFO2}
                </h3>
                <p className="body" style={{marginLeft: dimes.LARGEMARGIN, marginRight: dimes.LARGEMARGIN, textAlign: 'justify', color: dataApp.primary_color, fontSize: 18}}>
                    {dataApp.privacy.share_info.permissions_description}
                </p>
                <h3 className="subtitle" style={{marginLeft: dimes.LARGEMARGIN, marginBottom: dimes.SHORTMARGIN/2, color: dataApp.primary_color}}>
                    {strings.SHAREINFO3}
                </h3>
                <p className="body" style={{marginLeft: dimes.LARGEMARGIN, marginRight: dimes.LARGEMARGIN, textAlign: 'justify', color: dataApp.primary_color, fontSize: 18}}>
                    {strings.SHAREINFO3MSN}
                </p>
                <h3 className="subtitle" style={{marginLeft: dimes.LARGEMARGIN, marginBottom: dimes.SHORTMARGIN/2, color: dataApp.primary_color}}>
                    {strings.SHAREINFO4}
                </h3>
                <p className="body" style={{marginLeft: dimes.LARGEMARGIN, marginRight: dimes.LARGEMARGIN, textAlign: 'justify', color: dataApp.primary_color, fontSize: 18}}>
                    <ul style={{marginBottom: 0}}>
                        {
                            dataApp.privacy.share_info.toWho.map((who) => (
                                <li style={{color: dataApp.primary_color}}>
                                    <b>{who.who}</b>.{who.description}
                                </li>
                            ))
                        }
                    </ul>
                </p>
                </>
            }
            <h3 className="title" style={{marginBottom: dimes.SHORTMARGIN/2, color: dataApp.primary_color}}>
                {strings.THIRDPARTY}
            </h3>
            <p className="body" style={{marginLeft: dimes.LARGEMARGIN, marginRight: dimes.LARGEMARGIN, textAlign: 'justify', color: dataApp.primary_color, fontSize: 18}}>
                {strings.THIRDPARTYMSN}
            </p>
            <h3 className="title" style={{marginBottom: dimes.SHORTMARGIN/2, color: dataApp.primary_color}}>
                {strings.PRIVACYCHANGE}
            </h3>
            <p className="body" style={{marginLeft: dimes.LARGEMARGIN, marginRight: dimes.LARGEMARGIN, marginBottom: dimes.LARGEMARGIN, textAlign: 'justify', color: dataApp.primary_color, fontSize: 18}}>
                {strings.PRIVACYCHANGEMSN}
            </p>
        </div>
    );
}

export default Privacy;