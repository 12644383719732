import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter, Route, Switch} from "react-router-dom";

import Index from "../src/views/index";

import reportWebVitals from './reportWebVitals';

import "./assets/css/nucleo-icons.css";
import "./assets/css/demo.css";
import "./assets/scss/blk-design-system-react.scss?v=1.2.0";

ReactDOM.render(
  <BrowserRouter>
    <Switch>
      <Route path="/emojitxt/politicadeprivacidad" render={(props) => <Index {...props} toRender = 'Privacy'/>} />
      <Route path="/emojitxt" render={(props) => <Index {...props} toRender = 'Detail'/>} />
      <Route path="/memorizate/politicadeprivacidad" render={(props) => <Index {...props} toRender = 'Privacy'/>} />
      <Route path="/memorizate" render={(props) => <Index {...props} toRender = 'Detail'/>} />

      <Route path="/izzyscannerpro/politicadeprivacidad" render={(props) => <Index {...props} toRender = 'Privacy'/>} />
      <Route path="/izzyscannerpro" render={(props) => <Index {...props} toRender = 'Detail'/>} />
      <Route path="/carlostpro/politicadeprivacidad" render={(props) => <Index {...props} toRender = 'Privacy'/>} />
      <Route path="/carlostpro" render={(props) => <Index {...props} toRender = 'Detail'/>} />
      <Route path="/enigmapro/politicadeprivacidad" render={(props) => <Index {...props} toRender = 'Privacy'/>} />
      <Route path="/enigmapro" render={(props) => <Index {...props} toRender = 'Detail'/>} />
      <Route path="/izzymarketpro/politicadeprivacidad" render={(props) => <Index {...props} toRender = 'Privacy'/>} />
      <Route path="/izzymarketpro" render={(props) => <Index {...props} toRender = 'Detail'/>} />

      <Route path="/izzyscanner/politicadeprivacidad" render={(props) => <Index {...props} toRender = 'Privacy'/>} />
      <Route path="/izzyscanner" render={(props) => <Index {...props} toRender = 'Detail'/>} />
      <Route path="/carlost/politicadeprivacidad" render={(props) => <Index {...props} toRender = 'Privacy'/>} />
      <Route path="/carlost" render={(props) => <Index {...props} toRender = 'Detail'/>} />
      <Route path="/enigma/politicadeprivacidad" render={(props) => <Index {...props} toRender = 'Privacy'/>} />
      <Route path="/enigma" render={(props) => <Index {...props} toRender = 'Detail'/>} />
      <Route path="/izzymarket/politicadeprivacidad" render={(props) => <Index {...props} toRender = 'Privacy'/>} />
      <Route path="/izzymarket" render={(props) => <Index {...props} toRender = 'Detail'/>} />

      <Route path="/elementablepro/politicadeprivacidad" render={(props) => <Index {...props} toRender = 'Privacy'/>} />
      <Route path="/elementablepro" render={(props) => <Index {...props} toRender = 'Detail'/>} />
      <Route path="/izzystudypro/politicadeprivacidad" render={(props) => <Index {...props} toRender = 'Privacy'/>} />
      <Route path="/izzystudypro" render={(props) => <Index {...props} toRender = 'Detail'/>} />
      <Route path="/izzystatisticspro/politicadeprivacidad" render={(props) => <Index {...props} toRender = 'Privacy'/>} />
      <Route path="/izzystatisticspro" render={(props) => <Index {...props} toRender = 'Detail'/>} />
      <Route path="/cpeumpro/politicadeprivacidad" render={(props) => <Index {...props} toRender = 'Privacy'/>} />
      <Route path="/cpeumpro" render={(props) => <Index {...props} toRender = 'Detail'/>} />
      <Route path="/appapro/politicadeprivacidad" render={(props) => <Index {...props} toRender = 'Privacy'/>} />
      <Route path="/appapro" render={(props) => <Index {...props} toRender = 'Detail'/>} />

      <Route path="/elementable/politicadeprivacidad" render={(props) => <Index {...props} toRender = 'Privacy'/>} />
      <Route path="/elementable" render={(props) => <Index {...props} toRender = 'Detail'/>} />
      <Route path="/izzystudy/politicadeprivacidad" render={(props) => <Index {...props} toRender = 'Privacy'/>} />
      <Route path="/izzystudy" render={(props) => <Index {...props} toRender = 'Detail'/>} />
      <Route path="/zoodex/politicadeprivacidad" render={(props) => <Index {...props} toRender = 'Privacy'/>} />
      <Route path="/zoodex" render={(props) => <Index {...props} toRender = 'Detail'/>} />
      <Route path="/izzystatistics/politicadeprivacidad" render={(props) => <Index {...props} toRender = 'Privacy'/>} />
      <Route path="/izzystatistics" render={(props) => <Index {...props} toRender = 'Detail'/>} />
      <Route path="/cpeum/politicadeprivacidad" render={(props) => <Index {...props} toRender = 'Privacy'/>} />
      <Route path="/cpeum" render={(props) => <Index {...props} toRender = 'Detail'/>} />
      <Route path="/appa/politicadeprivacidad" render={(props) => <Index {...props} toRender = 'Privacy'/>} />
      <Route path="/appa" render={(props) => <Index {...props} toRender = 'Detail'/>} />

      <Route path="/detail" render={(props) => <Index {...props} toRender = 'Detail'/>} />
      <Route path="/" render={(props) => <Index {...props} toRender = 'Dashboard'/>} />
    </Switch>
  </BrowserRouter>,
  document.getElementById('root')
);

reportWebVitals();
