import React, {useState, useEffect} from 'react';
import {Link} from "react-router-dom";

import strings from '../styles/strings/strings';

import {Collapse, NavbarBrand, Navbar, NavItem, NavLink, Nav, Container, Row, Col, UncontrolledTooltip} from "reactstrap";
import {GrFacebook, GrInstagram, GrTwitter, GrYoutube} from "react-icons/gr";
import {SiGoogleplay, SiAppstore, SiHuawei} from "react-icons/si";

const IndexNavbar = () => {
  const [collapseOpen, setCollapseOpen] = useState(false);
  const [collapseOut, setCollapseOut] = useState("");
  const [color, setColor] = useState("navbar-transparent");

  useEffect(() => {
    window.addEventListener("scroll", changeColor);
    return function cleanup() {
      window.removeEventListener("scroll", changeColor);
    };
  },[]);
  
  const changeColor = () => {
    if (
      document.documentElement.scrollTop > 99 ||
      document.body.scrollTop > 99
    ) {
      setColor("bg-info");
    } else if (
      document.documentElement.scrollTop < 100 ||
      document.body.scrollTop < 100
    ) {
      setColor("navbar-transparent");
    }
  };

  const toggleCollapse = () => {
    document.documentElement.classList.toggle("nav-open");
    setCollapseOpen(!collapseOpen);
  };

  const onCollapseExiting = () => {
    setCollapseOut("collapsing-out");
  };

  const onCollapseExited = () => {
    setCollapseOut("");
  };

  const scrollToDownload = () => {
    document
      .getElementById("download-section")
      .scrollIntoView({behavior: "smooth"});
  };

  return (
    <Navbar className={"fixed-top " + color} color-on-scroll="100" expand="lg">
      <Container>
        <div className="navbar-translate">
          <NavbarBrand to="/" tag={Link} id="navbar-brand">
            <span>{strings.DASHBOARD} • </span>
            {strings.SLOGAN}
          </NavbarBrand>
          <UncontrolledTooltip placement="bottom" target="navbar-brand">
            {strings.DASHBOARDMSN}
          </UncontrolledTooltip>
          <button
            aria-expanded={collapseOpen}
            className="navbar-toggler navbar-toggler"
            onClick={toggleCollapse}
          >
            <span className="navbar-toggler-bar bar1" />
            <span className="navbar-toggler-bar bar2" />
            <span className="navbar-toggler-bar bar3" />
          </button>
        </div>
        <Collapse
          className={"justify-content-end " + collapseOut}
          navbar
          isOpen={collapseOpen}
          onExiting={onCollapseExiting}
          onExited={onCollapseExited}
        >
          <div className="navbar-collapse-header">
            <Row>
              <Col className="collapse-brand" xs="6">
                <a href="#pablo" onClick={(e) => e.preventDefault()}>
                  {strings.DASHBOARD} • {strings.DASHBOARDMSN}
                </a>
              </Col>
              <Col className="collapse-close text-right" xs="6">
                <button
                  aria-expanded={collapseOpen}
                  className="navbar-toggler"
                  onClick={toggleCollapse}
                >
                  <i className="tim-icons icon-simple-remove" />
                </button>
              </Col>
            </Row>
          </div>
          <Nav navbar>
            <NavItem className="p-0">
              <NavLink
                data-placement="bottom"
                href={strings.URLFACEBOOK}
                rel="noopener noreferrer"
                target="_blank"
                title={strings.NICKNAMEFACEBOOK}
              >
                <GrFacebook color="white" fontSize={"18px"} />
                <p className="d-lg-none d-xl-none">{strings.FACEBOOK}</p>
              </NavLink>
            </NavItem>
            <NavItem className="p-0">
              <NavLink
                data-placement="bottom"
                href={strings.URLINSTAGRAM}
                rel="noopener noreferrer"
                target="_blank"
                title={strings.NICKNAMEINSTAGRAM}
              >
                <GrInstagram color="white" fontSize={"18px"} />
                <p className="d-lg-none d-xl-none">{strings.INSTAGRAM}</p>
              </NavLink>
            </NavItem>
            <NavItem className="p-0">
              <NavLink
                data-placement="bottom"
                href={strings.URLTWITTER}
                rel="noopener noreferrer"
                target="_blank"
                title={strings.NICKNAMETWITTER}
              >
                <GrTwitter color="white" fontSize={"18px"} />
                <p className="d-lg-none d-xl-none">{strings.TWITTER}</p>
              </NavLink>
            </NavItem>
            <NavItem className="p-0">
              <NavLink
                data-placement="bottom"
                href={strings.URLYOUTUBE}
                rel="noopener noreferrer"
                target="_blank"
                title={strings.NICKNAMEYOUTUBE}
              >
                <GrYoutube color="white" fontSize={"18px"} />
                <p className="d-lg-none d-xl-none">{strings.YOUTUBE}</p>
              </NavLink>
            </NavItem>
            <NavItem className="p-0">
              <NavLink
                data-placement="bottom"
                href={strings.URLPLAYSTORE}
                rel="noopener noreferrer"
                target="_blank"
                title={strings.PLAYSTORE}
              >
                <SiGoogleplay color="white" fontSize={"18px"} />
                <p className="d-lg-none d-xl-none">{strings.PLAYSTORE}</p>
              </NavLink>
            </NavItem>
            <NavItem className="p-0">
              <NavLink
                data-placement="bottom"
                href={strings.URLAPPSTORE}
                rel="noopener noreferrer"
                target="_blank"
                title={strings.APPSTORE}
              >
                <SiAppstore color="white" fontSize={"18px"} />
                <p className="d-lg-none d-xl-none">{strings.APPSTORE}</p>
              </NavLink>
            </NavItem>
            <NavItem className="p-0">
              <NavLink
                data-placement="bottom"
                href={strings.URLAPPGALLERY}
                rel="noopener noreferrer"
                target="_blank"
                title={strings.APPGALLERY}
              >
                <SiHuawei color="white" fontSize={"18px"} />
                <p className="d-lg-none d-xl-none">{strings.APPGALLERY}</p>
              </NavLink>
            </NavItem>
          </Nav>
        </Collapse>
      </Container>
    </Navbar>
  );
}

export default IndexNavbar;