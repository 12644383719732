import React from "react";

import {Container} from "reactstrap";

const PageHeader = () => {
  return (
    <div className="page-header header-filter">
      <div className="squares square1" />
      <div className="squares square2" />
      <div className="squares square3" />
      <div className="squares square4" />
      <div className="squares square5" />
      <div className="squares square6" />
      <div className="squares square7" />
      <Container>
        <div className="content-center ">
          <img
            alt="..."
            className="img-center"
            src={require("../assets/img/banner_web.png")}
          />
          <h3 className="d-sm-block">
            Apps para dispositivos móviles
          </h3>
        </div>
      </Container>
    </div>
  );
}

export default PageHeader;