import React from "react";
import {Link} from "react-router-dom";

import strings from '../styles/strings/strings';

import {Button, NavItem, NavLink, Nav, Container, Row, Col, UncontrolledTooltip} from "reactstrap";
import {GrFacebook, GrInstagram, GrTwitter, GrYoutube} from "react-icons/gr";
import {SiGoogleplay, SiAppstore, SiHuawei} from "react-icons/si";

export default function Footer() {
  return (
    <footer className="footer">
      <Container>
        <Row>
          <Col md="3">
            <h1 className="title">{strings.COPYRIGHT}</h1>
          </Col>
          <Col md="3">
            <Nav>
              <NavItem>
                <NavLink to="/" tag={Link}>
                  {strings.HOME}
                </NavLink>
              </NavItem>
              <NavItem>
              <NavLink href="mailto:acertijos.contacto@gmail.com">
                {strings.CONTACTUS}
              </NavLink>
              </NavItem>
            </Nav>
          </Col>
          <Col md="3">
            <h3 className="title">{strings.DOWNLOADIN}</h3>
            <div className="btn-wrapper profile">
              <Button
                className="btn-icon btn-neutral btn-round btn-simple"
                color="default"
                href={strings.URLPLAYSTORE}
                id="tooltip230450891"
                target="_blank"
              >
                <SiGoogleplay color="white" fontSize={"20px"} />
              </Button>
              <UncontrolledTooltip delay={0} target="tooltip230450891">
                {strings.PLAYSTORE}
              </UncontrolledTooltip>
              <Button
                className="btn-icon btn-neutral btn-round btn-simple"
                color="default"
                href={strings.URLAPPSTORE}
                id="tooltip622135992"
                target="_blank"
              >
                <SiAppstore color="white" fontSize={"20px"} />
              </Button>
              <UncontrolledTooltip delay={0} target="tooltip622135992">
                {strings.APPSTORE}
              </UncontrolledTooltip>
              <Button
                className="btn-icon btn-neutral btn-round btn-simple"
                color="default"
                href={strings.URLAPPGALLERY}
                id="tooltip230450899"
                target="_blank"
              >
                <SiHuawei color="white" fontSize={"20px"} />
              </Button>
              <UncontrolledTooltip delay={0} target="tooltip230450899">
                {strings.APPGALLERY}
              </UncontrolledTooltip>
            </div>
          </Col>
          <Col md="3">
            <h3 className="title">{strings.FOLLOWUS}</h3>
            <div className="btn-wrapper profile">
              <Button
                className="btn-icon btn-neutral btn-round btn-simple"
                color="default"
                href={strings.URLFACEBOOK}
                id="tooltip230450801"
                target="_blank"
              >
                <GrFacebook color="white" fontSize={"20px"} />
              </Button>
              <UncontrolledTooltip delay={0} target="tooltip230450801">
                {strings.NICKNAMEFACEBOOK}
              </UncontrolledTooltip>
              <Button
                className="btn-icon btn-neutral btn-round btn-simple"
                color="default"
                href={strings.URLINSTAGRAM}
                id="tooltip622135962"
                target="_blank"
              >
                <GrInstagram color="white" fontSize={"20px"} />
              </Button>
              <UncontrolledTooltip delay={0} target="tooltip622135962">
                {strings.NICKNAMEINSTAGRAM}
              </UncontrolledTooltip>
              <Button
                className="btn-icon btn-neutral btn-round btn-simple"
                color="default"
                href={strings.URLTWITTER}
                id="tooltip318450378"
                target="_blank"
              >
                <GrTwitter color="white" fontSize={"20px"} />
              </Button>
              <UncontrolledTooltip delay={0} target="tooltip318450378">
                {strings.NICKNAMETWITTER}
              </UncontrolledTooltip>
              <Button
                className="btn-icon btn-neutral btn-round btn-simple"
                color="default"
                href={strings.URLYOUTUBE}
                id="tooltip230450809"
                target="_blank"
              >
                <GrYoutube color="white" fontSize={"20px"} />
              </Button>
              <UncontrolledTooltip delay={0} target="tooltip230450809">
                {strings.NICKNAMEYOUTUBE}
              </UncontrolledTooltip>
            </div>
          </Col>
        </Row>
      </Container>
    </footer>
  );
}
