export default {
    DASHBOARD: 'Acertijos',
    DASHBOARDMSN: 'Apps para dispositivos móviles',
    SLOGAN: 'El acertijo está en hacerlo',

    HOME: 'Inicio',

    SCHOOLAPPS: 'Apps para la escuela',
    SCHOOLAPPSMSN: 'Refuerza tu conocimiento o aprende cosas nuevas con nuestras apps educativas',
    PRODUCTIVEAPPS: 'Apps para la productividad',
    PRODUCTIVEAPPSMSN: 'Simplifica o mejora tú día a día con nuestras apps productivas',
    ENTERTAINMENTAPPS: 'Apps para el entretenimiento',
    ENTERTAINMENTAPPSMSN: 'Haz más divertido tu día con nuestras apps de entretenimiento',
 
    FREE: 'Gratuitas',
    PRO: 'De Paga',
    DESCRIP: 'Descripción',
    ABOUT: 'Acerca de',

    PRIVACYPOLICY: 'Política de privacidad',
    INTRODUCTION: '1. 	Introducción',
    COLLECTINFO: '2. 	Información que recopilamos sobre usted',
    COLLECTINFOMSN: 'No es necesario proporcionar información personal para usar la aplicación. No obstante, pudiera no disfrutar de ciertas funcionalidades de la aplicación.',
    COLLECTINFO1: '2.1. 	Información que nos proporciona',
    COLLECTINFO2: '2.2. 	Uso de la información recogida',
    SHAREINFO: '3. 	Información que compartimos sobre usted',
    SHAREINFOMSN: 'Nuestra aplicación puede llegar a compartir datos de su dispositivo móvil, esto se puede evitar deshabilitando los permisos de su dispositivo móvil. No obstante, si opta por no habilitarlas no podrá disfrutar de ciertas funcionalidades de la aplicación.',
    SHAREINFO1: '3.1	Información que compartimos',
    SHAREINFO2: '3.2. 	Uso de la información compartida',
    SHAREINFO3: '3.3.	Como compartimos su información',
    SHAREINFO3MSN: 'Sus datos se transfieren mediante una conexión segura, es decir, los datos están encriptaos en el transito de los mismos.\n'+
        'Estamos altamente comprometidos para cumplir con el compromiso de mantener su información segura, por lo que, usamos las librerías más avanzadas y las actualizamos constantemente para asegurarnos que no exista ningún acceso no autorizado.',
    SHAREINFO4: '3.4.	Con quien podemos compartir su información',
    THIRDPARTY: '4. 	Enlaces a Terceros',
    THIRDPARTYMSN: 'Esta aplicación pudiera contener enlaces a otros sitios que pudieran ser de su interés. Una vez que usted de clic en estos enlaces y abandone nuestra aplicación, ya no tenemos control sobre al sitio al que es redirigido y por lo tanto no somos responsables de los términos o privacidad ni de la protección de sus datos en esos otros sitios terceros. Dichos sitios están sujetos a sus propias políticas de privacidad por lo cual es recomendable que los consulte para confirmar que usted está de acuerdo con estas.',
    THIRDPARTY: '4. 	Enlaces a Terceros',
    PRIVACYCHANGE: '5. 	Modificaciones de la política de privacidad',
    PRIVACYCHANGEMSN: 'Podremos modificar esta Política de privacidad en cualquier momento cambiando su fecha de última actualización. Si hiciéramos cualquier cambio importante relativo a la forma en la que recopilamos, utilizamos o compartimos su información personal, explicaríamos dichos cambios y, si la legislación así lo exigiese, le pediríamos su consentimiento al respecto. le recomendamos y enfatizamos revisar continuamente esta página para asegurarse que está de acuerdo con dichos cambios. Esta Política de privacidad se actualizó el 07 de agosto de 2022.',

    CONTACTUS: 'Contáctanos',
    FACEBOOK: 'Facebook',
    FACEBOOKMSN: 'Danos like en Facebook',
    YOUTUBE: 'YouTube',
    YOUTUBEMSN: 'Danos like en YouTube',
    INSTAGRAM: 'Instagram',
    INSTAGRAMMSN: 'Síguenos en Instagram',
    TWITTER: 'Twitter',
    TWITTERMSN: 'Síguenos en Twitter',

    DOWNLOADIN: 'Descarga en:',
    STORES: 'Tiendas de apps',
    ANDROID: 'Android',
    TABLET: 'Tablet',
    GPLAYSTORE: 'Google\nPlay Store',
    PLAYSTORE: 'Play Store',
    HAPPGALLERY: 'Huawei\nAppGallery',
    APPGALLERY: 'AppGallery',
    IOS: 'iOS',
    AAPPSTORE: 'Apple App\nStore',
    APPSTORE: 'App Store',
    TUTORIAL: 'Vídeo\nTutorial',
    MOREINFO: 'Más información',

    FOLLOWUS: 'Síguenos en:',
    URLFACEBOOK: 'https://www.facebook.com/acertijosapps',
    NICKNAMEFACEBOOK: 'acertijosapps',
    URLYOUTUBE: 'https://www.youtube.com/@acertijosapps',
    NICKNAMEYOUTUBE: 'Acertijos Apps',
    URLINSTAGRAM: 'https://www.instagram.com/acertijos_apps',
    NICKNAMEINSTAGRAM: '@acertijos_apps',
    URLTWITTER: 'https://twitter.com/acertijos_apps',
    NICKNAMETWITTER: '@acertijos_apps',
    URLPLAYSTORE: 'https://play.google.com/store/apps/dev?id=6416841457999304408',
    URLAPPGALLERY: 'https://appgallery.huawei.com/#/tab/appdetailCommon%7CC104039551%7Cautomore%7Cdoublecolumncardwithstar%7C903547',
    URLAPPSTORE: 'https://apps.apple.com/developer/bryan-oswaldo-gomez-perez/id1573114808?see-all=i-phonei-pad-apps',
    EMAIL: 'acertijos.contacto@gmail.com',

    COPYRIGHT: "Acertijos 2022"
};