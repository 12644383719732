import React, {useEffect} from "react";

import IndexNavbar from "./indexNavbar";
import PageHeader from "./pageHeader";
import Dashboard from "./dashboard";
import Detail from "./detail";
import Privacy from "./privacy";
import Footer from "./footer";

const Index = ({toRender}) => {
    useEffect(() => {
        document.body.classList.toggle("index-page");

        return function cleanup() {
          document.body.classList.toggle("index-page");
        };
    },[]);

    const onRenderPage = () => {
        switch (toRender) {
            case 'Dashboard':
               return <>
                        <PageHeader />
                        <div className="main">
                            <Dashboard />
                        </div>
                    </>
            case 'Detail':
                return  <Detail />
            case 'Privacy':
                return  <Privacy />
            default:
                return <Detail />
        }
    }

    return (
        <>
            <IndexNavbar />
            <div className="wrapper">
                {onRenderPage()}
                <Footer />
            </div>
        </>
    )
}

export default Index;