import React, {useState, useEffect} from 'react';

import Apps from '../data/apps.json';
import strings from '../styles/strings/strings';
import dimes from '../styles/dimes';

import {useLocation, useHistory, Link} from 'react-router-dom';
import {Button, UncontrolledTooltip, Col, UncontrolledCarousel} from "reactstrap";
import {SiGoogleplay, SiAppstore, SiHuawei} from "react-icons/si";
import {IoHomeSharp, IoArrowBackSharp} from "react-icons/io5";

const Detail = ({data}) => {
    const [isLoading, setIsLoading] = useState(true);
    const [isDirect, setIsDirect] = useState(true);
    const [dataApp, setDataApp] = useState({});

    const {pathname, state={app: {}}} = useLocation();
    const history = useHistory();

    const educationFree = Apps.educationFree;
    const educationPro = Apps.educationPro;
    const toolsFree = Apps.toolsFree;
    const toolsPro = Apps.toolsPro;
    const entreteniment = Apps.entreteniment;

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    useEffect(() => {
        init();
    }, []);

    const init = () => {
        switch (pathname) {
            case '/appa':
                if(Object.keys(state.app).length === 0){
                    setDataApp(educationFree[0]);
                    setIsDirect(true);
                    setIsLoading(false);
                }else{
                    setDataApp(state.app);
                    setIsDirect(false);
                    setIsLoading(false);
                }
                break;
            case '/cpeum':
                if(Object.keys(state.app).length === 0){
                    setDataApp(educationFree[1]);
                    setIsDirect(true);
                    setIsLoading(false);
                }else{
                    setDataApp(state.app);
                    setIsDirect(false);
                    setIsLoading(false);
                }
                break;
            case '/izzystatistics':
                if(Object.keys(state.app).length === 0){
                    setDataApp(educationFree[2]);
                    setIsDirect(true);
                    setIsLoading(false);
                }else{
                    setDataApp(state.app);
                    setIsDirect(false);
                    setIsLoading(false);
                }
                break;
            case '/zoodex':
                if(Object.keys(state.app).length === 0){
                    setDataApp(educationFree[3]);
                    setIsDirect(true);
                    setIsLoading(false);
                }else{
                    setDataApp(state.app);
                    setIsDirect(false);
                    setIsLoading(false);
                }
                break;
            case '/izzystudy':
                if(Object.keys(state.app).length === 0){
                    setDataApp(educationFree[4]);
                    setIsDirect(true);
                    setIsLoading(false);
                }else{
                    setDataApp(state.app);
                    setIsDirect(false);
                    setIsLoading(false);
                }
                break;
            case '/elementable':
                if(Object.keys(state.app).length === 0){
                    setDataApp(educationFree[5]);
                    setIsDirect(true);
                    setIsLoading(false);
                }else{
                    setDataApp(state.app);
                    setIsDirect(false);
                    setIsLoading(false);
                }
                break;

            case '/appapro':
                if(Object.keys(state.app).length === 0){
                    setDataApp(educationPro[0]);
                    setIsDirect(true);
                    setIsLoading(false);
                }else{
                    setDataApp(state.app);
                    setIsDirect(false);
                    setIsLoading(false);
                }
                break;
            case '/cpeumpro':
                if(Object.keys(state.app).length === 0){
                    setDataApp(educationPro[1]);
                    setIsDirect(true);
                    setIsLoading(false);
                }else{
                    setDataApp(state.app);
                    setIsDirect(false);
                    setIsLoading(false);
                }
                break;
            case '/izzystatisticspro':
                if(Object.keys(state.app).length === 0){
                    setDataApp(educationPro[2]);
                    setIsDirect(true);
                    setIsLoading(false);
                }else{
                    setDataApp(state.app);
                    setIsDirect(false);
                    setIsLoading(false);
                }
                break;
            case '/izzystudypro':
                if(Object.keys(state.app).length === 0){
                    setDataApp(educationPro[3]);
                    setIsDirect(true);
                    setIsLoading(false);
                }else{
                    setDataApp(state.app);
                    setIsDirect(false);
                    setIsLoading(false);
                }
                break;
            case '/elementablepro':
                if(Object.keys(state.app).length === 0){
                    setDataApp(educationPro[4]);
                    setIsDirect(true);
                    setIsLoading(false);
                }else{
                    setDataApp(state.app);
                    setIsDirect(false);
                    setIsLoading(false);
                }
                break;

            case '/izzymarket':
                if(Object.keys(state.app).length === 0){
                    setDataApp(toolsFree[0]);
                    setIsDirect(true);
                    setIsLoading(false);
                }else{
                    setDataApp(state.app);
                    setIsDirect(false);
                    setIsLoading(false);
                }
                break;
            case '/enigma':
                if(Object.keys(state.app).length === 0){
                    setDataApp(toolsFree[1]);
                    setIsDirect(true);
                    setIsLoading(false);
                }else{
                    setDataApp(state.app);
                    setIsDirect(false);
                    setIsLoading(false);
                }
                break;
            case '/carlost':
                if(Object.keys(state.app).length === 0){
                    setDataApp(toolsFree[2]);
                    setIsDirect(true);
                    setIsLoading(false);
                }else{
                    setDataApp(state.app);
                    setIsDirect(false);
                    setIsLoading(false);
                }
                break;
            case '/izzyscanner':
                if(Object.keys(state.app).length === 0){
                    setDataApp(toolsFree[3]);
                    setIsDirect(true);
                    setIsLoading(false);
                }else{
                    setDataApp(state.app);
                    setIsDirect(false);
                    setIsLoading(false);
                }
                break;

            case '/izzymarketpro':
                if(Object.keys(state.app).length === 0){
                    setDataApp(toolsPro[0]);
                    setIsDirect(true);
                    setIsLoading(false);
                }else{
                    setDataApp(state.app);
                    setIsDirect(false);
                    setIsLoading(false);
                }
                break;
            case '/enigmapro':
                if(Object.keys(state.app).length === 0){
                    setDataApp(toolsPro[1]);
                    setIsDirect(true);
                    setIsLoading(false);
                }else{
                    setDataApp(state.app);
                    setIsDirect(false);
                    setIsLoading(false);
                }
                break;
            case '/carlostpro':
                if(Object.keys(state.app).length === 0){
                    setDataApp(toolsPro[2]);
                    setIsDirect(true);
                    setIsLoading(false);
                }else{
                    setDataApp(state.app);
                    setIsDirect(false);
                    setIsLoading(false);
                }
                break;
            case '/izzyscannerpro':
                if(Object.keys(state.app).length === 0){
                    setDataApp(toolsPro[3]);
                    setIsDirect(true);
                    setIsLoading(false);
                }else{
                    setDataApp(state.app);
                    setIsDirect(false);
                    setIsLoading(false);
                }
                break;

            case '/memorizate':
                if(Object.keys(state.app).length === 0){
                    setDataApp(entreteniment[0]);
                    setIsDirect(true);
                    setIsLoading(false);
                }else{
                    setDataApp(state.app);
                    setIsDirect(false);
                    setIsLoading(false);
                }
                break;
            case '/emojitxt':
                if(Object.keys(state.app).length === 0){
                    setDataApp(entreteniment[1]);
                    setIsDirect(true);
                    setIsLoading(false);
                }else{
                    setDataApp(state.app);
                    setIsDirect(false);
                    setIsLoading(false);
                }
                break;

            default:
                if(Object.keys(state.app).length === 0){
                    history.push('/')
                }else{
                    setDataApp(state.app);
                    setIsDirect(false);
                    setIsLoading(false);
                }
                break;
        }
    }

    return (
        isLoading ? 
        <></>
        :
        <div className="sectionG section-basic" style={{paddingTop: 0, paddingBottom: 0}}>
            <Button style={{position: "absolute", top: 90, left: dimes.MARGIN, background: dataApp.primary_color, zIndex: 99}}
                className="btn-icon btn-neutral btn-simple"
                color="default"
                id="tooltip230450899"
                onClick={() => isDirect ? history.push('/'):history.goBack()}>
                {isDirect ? <IoHomeSharp color="white" fontSize={"20px"} />:<IoArrowBackSharp color="white" fontSize={"20px"}/>}
            </Button>
            <UncontrolledTooltip delay={0} target="tooltip230450899">
                {"Regresar"}
            </UncontrolledTooltip>
            <div className="section section-basic" style={{paddingBottom: 0,}}>
                <div className="sectionV section-basic" id="basic-elements" style={{paddingTop:0, paddingBottom: 0}}>
                    <h1 className="title" style={{color: dataApp.primary_color, marginLeft: dimes.LARGEMARGIN, marginRight: dimes.LARGEMARGIN, textAlign: 'center'}}>
                        {dataApp.name}
                    </h1>
                    <h3 className="subtitle" style={{color: dataApp.primary_color, marginLeft: dimes.LARGEMARGIN, marginRight: dimes.LARGEMARGIN, textAlign: 'center'}}>
                        {dataApp.subtitle}
                    </h3>
                    <div className="btn-wrapper profile">
                        <Button style={{background: dataApp.primary_color}}
                            className="btn-icon btn-neutral btn-round btn-simple"
                            color="default"
                            href={dataApp.play_store}
                            id="tooltip230450891"
                            target="_blank">
                            <SiGoogleplay color="white" fontSize={"20px"} />
                        </Button>
                        <UncontrolledTooltip delay={0} target="tooltip230450891">
                            {strings.PLAYSTORE}
                        </UncontrolledTooltip>
                        {
                            dataApp.app_store !== "" &&
                            <>
                            <Button style={{background: dataApp.primary_color}}
                                className="btn-icon btn-neutral btn-round btn-simple"
                                color="default"
                                href={dataApp.app_store}
                                id="tooltip622135992"
                                target="_blank">
                                <SiAppstore color="white" fontSize={"20px"} />
                            </Button>
                            <UncontrolledTooltip delay={0} target="tooltip622135992">
                                {strings.APPSTORE}
                            </UncontrolledTooltip>
                            </>
                        }

                        {
                            dataApp.app_gallery !== "" &&
                            <>
                            <Button style={{background: dataApp.primary_color}}
                                className="btn-icon btn-neutral btn-round btn-simple"
                                color="default"
                                href={dataApp.app_gallery}
                                id="tooltip230450899"
                                target="_blank">
                                <SiHuawei color="white" fontSize={"20px"} />
                            </Button>
                            <UncontrolledTooltip delay={0} target="tooltip230450899">
                                {strings.APPGALLERY}
                            </UncontrolledTooltip>
                            </>
                        }
                    </div>
                    {
                        dataApp.privacy !== null &&
                        <Link style={{fontSize: 18, fontFamily: 'inherit', fontWeight: '400', textDecorationLine: "underline", color: dataApp.primary_color}}
                            to={{pathname: pathname+"/politicadeprivacidad", state: {app: state.app}}}>
                            {strings.PRIVACYPOLICY}
                        </Link>
                    }
                </div>
                <div className="sectionV section-basic" id="images">
                    <img
                        alt="..."
                        className="img-fluid rounded shadow-lg"
                        src={dataApp.icon}
                        style={{width: "195px", height: "195px", marginTop: 0, marginBottom: 0}}/>
                </div>
            </div>
            <h2 className="title" style={{marginTop: 0, marginBottom: dimes.SHORTMARGIN/2, color: dataApp.primary_color}}>
                {strings.DESCRIP}
                </h2>
            <p className="body" style={{marginLeft: dimes.LARGEMARGIN, marginRight: dimes.LARGEMARGIN, marginBottom: 0, textAlign: 'justify', color: dataApp.primary_color, fontSize: 18}}>
                {dataApp.descrip_large}
            </p>
            <div className="section section-basic" style={{paddingTop: dimes.LARGEMARGIN, paddingBottom: dimes.LARGEMARGIN}}>
                {
                    dataApp.ios_img.length !== 0 &&
                    <Col md="2">
                        <UncontrolledCarousel
                            items={dataApp.ios_img}
                            controls={true}
                            indicators={true}
                            autoPlay={false}/>
                        <h2 className="title" style={{marginBottom: dimes.LARGEMARGIN, color: dataApp.primary_color}}>{strings.IOS}</h2>
                    </Col>
                }
                {
                    dataApp.android_img.length !== 0 &&
                    <Col md="2">
                        <UncontrolledCarousel 
                            items={dataApp.android_img}
                            controls={true}
                            indicators={true}
                            autoPlay={false}/>
                        <h2 className="title" style={{marginBottom: dimes.LARGEMARGIN, color: dataApp.primary_color}}>{strings.ANDROID}</h2>
                    </Col>
                }
                {
                    dataApp.tablet_img.length !== 0 &&
                    <Col md="3">
                        <UncontrolledCarousel
                            items={dataApp.tablet_img}
                            controls={true}
                            indicators={true}
                            autoPlay={false}/>
                        <h2 className="title" style={{marginBottom: dimes.LARGEMARGIN, color: dataApp.primary_color}}>{strings.TABLET}</h2>
                    </Col>
                }
            </div>
        </div>
    );
}

export default Detail;